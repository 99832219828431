import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { OfferWrapper, OfferHeading, OfferDescription, ExtraDescription, IconWrapper, InlineWrapper, OfferLink, SignatureWrapper } from '../assets/styles';
import SEO from '../components/seo'
import {StaticImage} from "gatsby-plugin-image";
import {Reservation} from "../components";

const ReservationPage = () => {
    const intl = useIntl();

    return (
      <>
        <SEO title="Offer" />
        <OfferWrapper>
            <OfferHeading ru={intl.locale}>{intl.formatMessage({ id: "reservation" })}</OfferHeading>
            <OfferDescription>{intl.formatMessage({ id: "reservation_description" })}</OfferDescription>
          <Reservation />
            <InlineWrapper>
              <IconWrapper>
                <StaticImage quality={100} width={34} height={34} src='../assets/images/phone.png' alt="email icon"/>
              </IconWrapper>
                <OfferDescription>735 935 489</OfferDescription>
            </InlineWrapper>
            <InlineWrapper>
              <IconWrapper>
                <StaticImage quality={100} width={34} height={34} src='../assets/images/mailIco.png' alt="email icon"/>
              </IconWrapper>
                <OfferLink href="mailto:kpclaudia@orange.pl">kpclaudia@orange.pl</OfferLink>
            </InlineWrapper>
            <OfferDescription>{intl.formatMessage({ id: "reservation_online" })}</OfferDescription>
            <InlineWrapper>
              <IconWrapper>
                <StaticImage quality={100} width={34} height={34} src='../assets/images/out.png' alt="external link icon"/>
              </IconWrapper>
                <OfferLink target="_blank" rel="noopener noreferrer" href="https://www.booking.com/hotel/pl/claudia.pl.html">Booking.com</OfferLink>
            </InlineWrapper>
            <OfferHeading ru={intl.locale}>{intl.formatMessage({ id: "offer" })}</OfferHeading>
            <OfferDescription>{intl.formatMessage({ id: "offer_description" })}</OfferDescription>
            <ExtraDescription>{intl.formatMessage({ id: "offer_extra_description" })}</ExtraDescription>
            <OfferHeading ru={intl.locale}>{intl.formatMessage({ id: "breakfast" })}</OfferHeading>
            <OfferDescription>{intl.formatMessage({ id: "breakfast_description" })}</OfferDescription>
            <OfferHeading ru={intl.locale}>{intl.formatMessage({ id: "social_media" })}</OfferHeading>
            <OfferDescription>{intl.formatMessage({ id: "social_media_description" })}</OfferDescription>
            <InlineWrapper>
              <IconWrapper>
                <StaticImage quality={100} width={34} height={34} src='../assets/images/fb.png' alt="facebook icon"/>
              </IconWrapper>
                <OfferLink target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/klubmaluchabrzoskwinka/">Klub Malucha Brzoskwinka</OfferLink>
            </InlineWrapper>
            <InlineWrapper>
                <IconWrapper>
                  <StaticImage quality={100} width={34} height={34} src='../assets/images/fb.png' alt="facebook icon" />
                </IconWrapper>
                <OfferLink target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/ppbrzoskwinka/">Przedszkole Brzoskwinka</OfferLink>
            </InlineWrapper>
            <SignatureWrapper ru={intl.locale}>
                {intl.formatMessage({ id: "signature" })}<br />
                {intl.formatMessage({ id: "signature2" })}
            </SignatureWrapper>
        </OfferWrapper>
        </>
    );
}

export default ReservationPage
